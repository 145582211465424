export function getToken() {
    let _t = localStorage.getItem("_ca");
    if(_t !== undefined && _t !== null){
        _t = JSON.parse(_t);
        return _t.token;
    }
    return null;
}

export function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);

        const responseData = {
        error: false,
        data: data,
        };
        if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            //logout();
        }

        responseData.error = true;
        return responseData;
        }
        return responseData;
    });
}

export function IntToBool(i){
    return (i === 1) ? true : false;
}