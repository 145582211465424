import React, { lazy, Suspense } from 'react';
import './index.css';
import App from './App';
import { render } from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import {createBrowserHistory} from "history";
import AppProvider from "./context";
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from './components/loading';

const Login         = lazy(() => import('./pages/login'));
const List          = lazy(() => import('./pages/list'));
// const Categories    = lazy(() => import('./pages/categories'));
// const Business      = lazy(() => import('./pages/business'));
// const Seller        = lazy(() => import('./pages/seller'));
// const Signup        = lazy(() => import('./pages/signup'));
// const StoreCreation = lazy(() => import('./pages/store_creation'));

var hist = createBrowserHistory();

render(
  <AppProvider>
    <Router history={hist}>
      <Suspense fallback={<Loading loadingPage={true} />}>
        <Switch>
          <Route path="/login" component={Login}></Route>
          <Route path="/list" component={List}></Route>
          {/* <Route path="/category/:categoryid?" component={Categories}></Route> */}
          {/* <Route path="/business/:submenu?/:regid?" component={Business}></Route> */}
          {/* <Route path="/products/:categoryid?/:sellerid?" component={Categories}></Route> */}
          {/* <Route path="/signup" component={Signup}></Route> */}
          {/* <Route path="/designStore" component={StoreCreation}></Route> */}
          {/* <Route path="/:sellerName" component={Seller}></Route> */}
          <Route path="/" component={App}></Route>
        </Switch>
      </Suspense>
    </Router>
  </AppProvider>, 
  document.getElementById("root")
);