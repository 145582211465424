import { API_URL } from "./api.url.back";
import { getToken, handleResponse } from "./services.functions";

export const usersServices = {
    login
};

function login(user, pass) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            username : user,
            password : pass
        })
    };

    return fetch(`${API_URL}/users/login`, requestOptions)
        .then(handleResponse)
        .then((data) => {
        return data;
    });
}
