import React from 'react';

import under_construction from "./resources/under_construction.jpg";
import "./styles/main.css";

function App() {
  return (
    <div className='main-div-img' style={{background: `url(${under_construction})`}}>
      <label className='main-lbl'>UNDER CONSTRUCTION</label>
    </div>
  );
}

export default App;
