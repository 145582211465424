import React, { createContext, useState, useEffect } from "react";

import { usersServices } from "./functions/users.services";

export const AppContext = createContext();

const AppProvider = ({ children }) => {
    const [user,       setUser]       = useState(null);
    const [loading,    setLoading]    = useState(false);
    /// ALERTS
    const [showAlert,       setShowAlert]       = useState(false);
    const [alertTitle,      setAlertTitle]      = useState(null);
    const [alertSubtitle,   setAlertSubtitle]   = useState(null);
    const [alertAdditional, setAlertAdditional] = useState(null);
    const [alertPosition,   setAlertPosition]   = useState('top-start');
    const [alertType,       setAlertType]       = useState("info");

    const getUserData = async () => {
        let _myUser = await usersServices.getMyUser();
        if(_myUser.error === false && _myUser.data !== undefined){
            setUser(_myUser.data);
        }
    }

    const goTo = (link) => {
        window.location.href = link;
    };

    useEffect(() => {
        if(user === null){
            let _t = localStorage.getItem("_vg");
            if(_t !== undefined && _t !== null){
                _t = JSON.parse(_t);
                if(_t.token !== undefined && _t.token !== null){
                    getUserData();
                }
            }
        }
    }, []);

    const value = {
        /// Variables
        user,
        /// Hooks
        loading,         setLoading,
        showAlert,       setShowAlert,
        alertTitle,      setAlertTitle,
        alertSubtitle,   setAlertSubtitle,
        alertAdditional, setAlertAdditional,
        alertPosition,   setAlertPosition,
        alertType,       setAlertType,
        /// Funcions
        goTo
    };
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;